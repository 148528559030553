import { useEffect, useRef } from 'react'
import { Box } from '@lib/UIComponents'

declare global {
  interface Window {
    gladlyHCConfig: {
      api: string
      orgId: string
      brandId: string
      cdn: string
      selector: string
    }
  }
}

const styles = {
  box: {
    lineHeight: '1.5',
    input: {
      padding: '10px 20px 10px 20px',
      border: '1px solid #ccc',
      borderRadius: '20px'
    },
    h2: {
      fontSize: 'xl',
      color: 'secondary',
      margin: '20px 0'
    },
    h3: {
      fontWeight: 'bold',
      mb: '10px'
    },
    a: {
      color: 'primary',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    'ul, ol': {
      padding: '20px 0 10px 30px',
      li: {
        mb: '10px'
      }
    },
    ul: {
      listStyleType: 'circle'
    },
    '.gladlyHC-faqSections': {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '20px'
    }
  }
}

const GladlyHelpCenter: React.FC = () => {
  const boxRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    window.gladlyHCConfig = {
      api: 'https://stackcommerce.us-1.gladly.com',
      orgId: 'S_8nv7jeT0-M37jEoMClHQ',
      brandId: '1_jFWZw5QTCpUb-hug3zkw',
      cdn: 'https://cdn.gladly.com',
      selector: '#gladly-help-center'
    }

    const loadGladlyScript = () => {
      const scriptElement = document.createElement('script')
      scriptElement.type = 'text/javascript'
      scriptElement.async = true
      scriptElement.src = 'https://cdn.gladly.com/help-center/hcl.js'
      boxRef.current?.appendChild(scriptElement)
    }

    loadGladlyScript()
  }, [])

  return <Box ref={boxRef} id="gladly-help-center" sx={styles.box} />
}

export default GladlyHelpCenter
