import { GetServerSideProps } from 'next'
import { Box, Heading, Link, Text } from '@lib/UIComponents'
import { withPublisher } from '@middlewares/tenancy/publisher'
import Layout from '@templates/Layout/Layout'
import { ApplicationPage } from 'src/types/page-props'
import GladlyHelpCenter from '@concepts/FAQ/views/GladlyHelpCenter'
import { supportLinks } from '@utils/supportLinks'
import Seo from '@concepts/Seo/Seo'
import { HELP } from '@concepts/Seo/constants/pages'

const styles = {
  box: {
    maxWidth: '60%',
    margin: 'auto'
  },
  contactLink: {
    fontWeight: 'bold',
    textDecoration: 'underline'
  }
}

const Help: ApplicationPage = () => {
  return (
    <Layout>
      <Seo page={HELP} />
      <Box sx={styles.box}>
        <Heading sx={{ mb: '30px' }}>Help Center</Heading>
        <Text as="p" sx={{ mb: '15px' }}>
          Didn't find what you're looking for?{' '}
          <Link href={supportLinks.contact()} sx={styles.contactLink}>
            Contact us.
          </Link>
        </Text>
        <GladlyHelpCenter />
      </Box>
    </Layout>
  )
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  return withPublisher(context, {}, { fetchSSRUser: false })
}

export default Help
