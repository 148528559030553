/* istanbul ignore file */
export const ACCESSIBILITY_PAGE = 'Accessibility'
export const CART_EMPTY_PAGE = 'Cart empty'
export const CHECKOUT_PAGE = 'Checkout'
export const CART_PAGE = 'Cart'
export const FORGOT_YOUR_PASSWORD = 'Forgot Your Password'
export const PRIVACY_PAGE = 'Privacy'
export const RESET_PASSWORD = 'Reset Password'
export const SIGN_IN = 'Sign In'
export const SIGN_UP = 'Sign Up'
export const TERMS_PAGE = 'Terms'
export const ARTICLES_PAGE = 'Articles'
export const NOT_FOUND = 'Page Not Found'
export const INTERNAL_SERVER_ERROR = 'Something Went Wrong!'
export const CONTACT = 'Contact Us'
export const HELP = 'Help Center'

export const ORDER_PAGE = (id: number): string => `Order #${id} Confirmation`
export const SEARCH_PAGE = (query: string): string => `Search "${query}"`
export const REVIEW_PAGE = (id: number): string => `Review #${id}`
